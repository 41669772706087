import React from "react";
import styles from "./Press.module.css";

const Press = () => {
  return (
    <div className={styles.wrapper} id="press">
      <h2 className={styles.title}>PRESS</h2>
      <div className={styles.infoWrapper}>
      <p className={styles.info}>
          <a href="https://collider.com/orion-and-the-dark-end-credit-song-explained/" target="_blank" rel="noreferrer">Orion and the Dark End Credit Song Explained</a>
        </p>
        <p className={styles.info}>
          <a href="https://www.thewrap.com/orion-and-the-dark-exclusive-video/" target="_blank" rel="noreferrer">Orion and the Dark Exclusive video</a>
        </p>

      <p className={styles.info}>
          <a href="https://deadline.com/2023/12/2023-children-and-family-emmy-winners-list-1235670724/" target="_blank" rel="noreferrer">Kevin Lax wins first Emmy for Kung Fu Panda music</a>
        </p>
        <p className={styles.info}>
          <a href="https://www.musicconnection.com/out-take-kevin-lax/" target="_blank" rel="noreferrer">Out Take: Kevin Lax</a>
        </p>
        <p className={styles.info}>
          <a href="https://splashmags.com/index.php/2018/08/18/kevin-lax-on-the-politics-of-fear/#gsc.tab=0" target="_blank" rel="noreferrer">Kevin Lax on the Politics of Fear</a>
        </p>
        <p className={styles.info}>
          <a href="https://www.dreadcentral.com/news/277896/interview-composer-kevin-lax-on-scoring-the-first-purge/" target="_blank" rel="noreferrer">Composer Kevin Lax on Scoring the First Purge</a>
        </p>
        <p className={styles.info}>
          <a href="https://www.youtube.com/watch?v=y2bCrYJqIks" target="_blank" rel="noreferrer">The First Purge: Interview with composer Kevin Lax</a>
        </p>
      </div>
    </div>
  );
};

export default Press;
