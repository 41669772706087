import React, { useState } from "react";
import { imdb, instagram, soundCloud, spotify } from "../../images";
import styles from "./Contact.module.css";
import { useForm } from '@formspree/react';
const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [state, handleSubmit] = useForm("xeqbdpnv");
  const socials = [
    {
      label: "Listen",
      social: [
        { icon: soundCloud, to: "https://soundcloud.com/kevin-lax" },
        { icon: spotify, to: "https://open.spotify.com/artist/0vkpJcFIeZZEITGmRyh2US?si=0q5cWpHJQgiaBDnmP2virA" },
      ],
    },
    {
      label: "Follow",
      social: [
        { icon: imdb, to: "http://www.imdb.com/name/nm3400871/" },
        { icon: instagram, to: "https://www.instagram.com/kevinlaxcomposer/" },
      ],
    },
  ];
  return (
    <div className={[styles.wrapper, "mainWrapper"].join(" ")} id="contact">
      <h2 className={styles.title}>CONTACT</h2>
      <div className={styles.container}>
        <div>
      <h4 className={styles.heading}>Get In Touch</h4>
      <form onSubmit={handleSubmit} className={styles.form}>
      <input
            name="name"
            type="name"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
          />
          <input
            name="email"
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
          />

          <textarea
            name="message"
            id="message"
            placeholder="Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={styles.textArea}
          ></textarea>
      <button type="submit" disabled={state.submitting} className={styles.button}>Submit</button>
      {state.succeeded && <p className={styles.notification}>Your message has been sent. Thank you!</p>}
      {state.submitting && <p className={styles.notification}>Sending now...</p>}
      {state.errors && <p className={styles.notification}>Something went wrong. Please try again!</p>}
    </form></div>
        <div className={styles.textContainer}>
          <h4 className={styles.heading}>Film Representation</h4>
          <div className={styles.infoWrapper}>
            <div>
              <p className={[styles.info, styles.highlight].join(" ")}>
                First Artists Management
              </p>
              <p className={styles.info}>Alexander Vangelos (Agent)</p>
              <a href="www.firstartistsmgmt.com" target="_blank" rel="noreferrer" className={styles.info}>www.firstartistsmgmt.com</a><br />
              <a className={styles.info} href="mailto:AVangelos@firstartistsmgmt.com" target="_blank" rel="noreferrer">AVangelos@firstartistsmgmt.com</a>
            </div>
            <div>
              <p className={styles.info}>Phone: +1.818.377.7750</p>
              <p className={styles.info}>Address: 4764 Park Granada Ste 110</p>
              <p className={styles.info}>Calabasas, CA 91302, USA</p>
            </div>
            <div className={styles.socialContainer}>
              {socials.map((el, i) => (
                <div key={i}>
                  <p className={styles.label}>{el.label}</p>
                  <div className={styles.socials}>
                    {el.social.map((item, id) => (
                      <a
                        href={item.to}
                        target="_blank"
                        key={id}
                        rel="noreferrer"
                      >
                        <img
                          src={item.icon}
                          alt="#"
                          className={styles.socialIcon}
                        />
                      </a>
                    ))}{" "}
                  </div>
                </div>
              ))}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
